import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        title: $setup.t('myPaymentMethods'),
        parentPath: "/profile"
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_loading_skeleton, { key: 0 }))
                : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.stripePaymentMethods, (m, i) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          lines: "full",
                          key: m.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: $setup.cardOutline,
                              slot: "start"
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, [
                                  (m.card.brand == 'visa')
                                    ? (_openBlock(), _createElementBlock("b", _hoisted_1, "Visa"))
                                    : (m.card.brand == 'mastercard')
                                      ? (_openBlock(), _createElementBlock("b", _hoisted_2, "MasterCard"))
                                      : (_openBlock(), _createElementBlock("b", _hoisted_3, _toDisplayString(m.card.brand), 1)),
                                  _createElementVNode("small", null, " (" + _toDisplayString(m.card.exp_month) + "/" + _toDisplayString(m.card.exp_year) + ")", 1)
                                ]),
                                _createElementVNode("p", null, "**** **** **** " + _toDisplayString(m.card.last4), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_buttons, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_button, {
                                  slot: "icon-only",
                                  onClick: ($event: any) => ($setup.deletePaymentMethod(i, m))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, { icon: $setup.trashOutline }, null, 8, ["icon"])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}